import React from "react";
import { Link } from "react-router-dom"; // ✅ Import Link for navigation
import "../../styles/Subsections.css"; // ✅ Linked to universal stylesheet

const Statements = () => {
  return (
    <div className="subsection-container">
      <h1 className="subsection-title">Financial Statements</h1>

      <section>
      <h2>Diving Into Company Financials</h2>
        <p>
          Understanding a company’s financials is like learning to read a new language—it might seem intimidating at first, but once you know the basics, you can uncover a wealth of information about a company’s health, performance, and potential. Whether you are investing, job hunting, or just curious, knowing how to dive into company financials yourself gives you an edge. Let us break it down step by step.
        </p>
      </section>

      <section>
        <h2>Why Company Financials Matter</h2>
        <p>
          At its core, a company’s financial statements tell you how well it is doing—without the fluff of marketing or PR. They are the raw numbers that show:
        </p>
        <ul>
          <li>How much money the company makes (revenue).</li>
          <li>What it spends (expenses).</li>
          <li>What it owns (assets).</li>
          <li>What it owes (liabilities).</li>
          <li>How much is left for owners or investors (equity).</li>
        </ul>
        <p>
          If you are investing, these numbers help you decide if a company is worth your money. If you are applying for a job, they reveal whether the company is stable enough to pay you long-term. Even if you are just a consumer, understanding financials can show you if a company you rely on is likely to stick around.
        </p>
      </section>

      <section>
        <h2>Key Financial Statements</h2>
        <p>
          Companies publish 3 main financial statements. Each statement tells a different part of the story, and you need all 3 statements to get the full picture.
        </p>

        <h3>1. Income Statement: The Profit Story</h3>
        <p>
          The income statement shows how much money a company made (or lost) over a specific period—usually a quarter or a year. Think of it as a report card for the company’s profitability.
        </p>
        <ul>
          <li><strong>Revenue (Top Line):</strong> This is the total money the company brings in from sales or services. It is the starting point.</li>
          <li><strong>Expenses:</strong> These are the costs of running the business—things like salaries, rent, marketing, and production.</li>
          <li><strong>Net Income (Bottom Line):</strong> Revenue minus expenses. If it is positive, the company made a profit. If it is negative, they lost money.</li>
        </ul>
        <p>
          <strong>Example:</strong><br />
          A tech company reports $10 million in revenue.<br />
          Expenses (salaries, R&D, marketing) total $8 million.<br />
          Net Income: $10M - $8M = $2 million profit.
        </p>
        <p>
          But here is the catch: a company can have high revenue and still lose money if expenses are out of control. Look at the trends—has net income been growing or shrinking over the past few years?
        </p>

        <h3>2. Balance Sheet: The Snapshot of Health</h3>
        <p>
          The balance sheet is a snapshot of what the company owns and owes at a specific moment in time. It is divided into 3 main parts:
        </p>
        <ul>
          <li><strong>Assets:</strong> What the company owns—cash, inventory, property, equipment, patents, etc.</li>
          <li><em>Current Assets:</em> Can be turned into cash within a year (e.g., cash, accounts receivable).</li>
          <li><em>Long-Term Assets:</em> Things like buildings or machinery that last longer.</li>
          <li><strong>Liabilities:</strong> What the company owes—loans, unpaid bills, taxes, etc.</li>
          <li><em>Current Liabilities:</em> Due within a year (e.g., short-term debt).</li>
          <li><em>Long-Term Liabilities:</em> Due beyond a year (e.g., a 10-year loan).</li>
          <li><strong>Equity:</strong> What is left for the owners after paying off liabilities (Assets - Liabilities = Equity).</li>
        </ul>
        <p>
          <strong>Example:</strong><br />
          A company has $50 million in assets (cash, equipment, etc.).<br />
          It owes $30 million in liabilities (loans, bills).<br />
          Equity: $50M - $30M = $20 million.
        </p>
        <p>
          The balance sheet must always balance: Assets = Liabilities + Equity. If a company has more liabilities than assets, it is a red flag—it might be at risk of going under.
        </p>

        <h3>3. Cash Flow Statement: The Money Movement</h3>
        <p>
          The cash flow statement shows how cash moves in and out of the company over a period. It is broken into 3 sections:
        </p>
        <ul>
          <li><strong>Operating Activities:</strong> Cash from the core business (e.g., selling products). Positive cash flow here is a good sign the business is sustainable.</li>
          <li><strong>Investing Activities:</strong> Cash spent on or received from investments (e.g., buying equipment or selling a factory). Negative cash flow here is not always bad—it might mean the company is investing in growth.</li>
          <li><strong>Financing Activities:</strong> Cash from borrowing or paying off debt, issuing stock, or paying dividends. This shows how the company funds itself.</li>
        </ul>
        <p>
          <strong>Example:</strong><br />
          A company generates $5 million from operations (good sign).<br />
          It spends $3 million on new equipment (investing).<br />
          It pays $1 million in dividends (financing).<br />
          Net Cash Flow: $5M - $3M - $1M = $1 million increase in cash.
        </p>
        <p>
          Cash is king. A company can be profitable on the income statement but still fail if it runs out of cash. Always check the cash flow statement to see if the company can actually pay its bills.
        </p>
      </section>

      <section>
        <h2>Where to Find Financial Statements</h2>
        <p>
          Public companies (those listed on stock exchanges like the NYSE or NASDAQ) are required to publish their financial statements. Here is how to get them:
        </p>
        <ul>
          <li><strong>Company Website:</strong> Check the “Investor Relations” section—most companies post their annual reports (called 10-Ks in the U.S.) and quarterly reports (10-Qs).</li>
          <li><strong>SEC Website:</strong> The U.S. Securities and Exchange Commission (SEC) has a database called EDGAR where you can search for any public company’s filings (<a href="https://sec.gov/edgar" target="_blank" rel="noopener noreferrer">sec.gov/edgar</a>).</li>
          <li><strong>Financial News Sites:</strong> Sites like Yahoo Finance, Google Finance, or MarketWatch provide summaries of financial statements, though you should always cross-check with the original reports.</li>
        </ul>
        <p>
          Private companies are harder to research—they are not required to disclose financials publicly. If you are interested in a private company, you might need to dig into industry reports, news articles, or ask directly if you are a potential investor or employee.
        </p>
      </section>

      <section>
        <h2>Key Metrics to Understand Financials</h2>
        <p>
          Once you have the financial statements, here are some key metrics to calculate and analyze. These will help you understand the company’s performance and potential.
        </p>

        <h3>1. Profit Margins: How Efficient Is the Company?</h3>
        <p>
          Profit margins show how much of the revenue turns into profit. There are a few types:
        </p>
        <ul>
          <li><strong>Gross Margin:</strong> (Revenue - Cost of Goods Sold) / Revenue</li>
          <li>Measures how much money is left after paying for the direct costs of producing goods or services.</li>
          <li><em>Example:</em> If revenue is $10 million and the cost of goods sold is $6 million, Gross Margin = ($10M - $6M) / $10M = 40%.</li>
          <li><strong>Net Margin:</strong> Net Income / Revenue</li>
          <li>Shows how much profit the company keeps after all expenses.</li>
          <li><em>Example:</em> If net income is $2 million and revenue is $10 million, Net Margin = $2M / $10M = 20%.</li>
        </ul>
        <p>
          Higher margins are better—they mean the company is efficient at turning revenue into profit. Compare margins to competitors in the same industry to see if the company is performing well.
        </p>

        <h3>2. Debt-to-Equity Ratio: Is the Company Overleveraged?</h3>
        <p>
          The debt-to-equity ratio (Total Liabilities / Equity) shows how much debt the company is using to finance its operations compared to its equity.
        </p>
        <p>
          <em>Example:</em> If liabilities are $30 million and equity is $20 million, Debt-to-Equity = $30M / $20M = 1.5.
        </p>
        <p>
          A ratio above 1 means the company has more debt than equity. This is not always bad—some industries (like utilities) naturally carry more debt—but a very high ratio (e.g., 3 or higher) can be a red flag, especially if cash flow is weak.
        </p>

        <h3>3. Current Ratio: Can the Company Pay Its Bills?</h3>
        <p>
          The current ratio (Current Assets / Current Liabilities) measures the company’s ability to pay short-term obligations.
        </p>
        <p>
          <em>Example:</em> If current assets are $10 million and current liabilities are $5 million, Current Ratio = $10M / $5M = 2.
        </p>
        <p>
          A ratio above 1 means the company can cover its short-term debts. Below 1 is a warning sign—it might struggle to pay bills on time.
        </p>

        <h3>4. Return on Equity (ROE): How Well Does the Company Use Its Money?</h3>
        <p>
          ROE (Net Income / Equity) shows how efficiently the company uses its equity to generate profit.
        </p>
        <p>
          <em>Example:</em> If net income is $2 million and equity is $20 million, ROE = $2M / $20M = 10%.
        </p>
        <p>
          A higher ROE is better—it means the company is good at turning owners’ money into profit. Compare ROE to industry averages to see if the company is outperforming its peers.
        </p>
      </section>

      <section>
        <h2>Red Flags to Watch For</h2>
        <p>
          When analyzing financials, look out for these warning signs:
        </p>
        <ul>
          <li><strong>Declining Revenue or Net Income:</strong> If revenue or profits are shrinking year over year, the company might be losing market share or facing operational issues.</li>
          <li><strong>High Debt with Low Cash Flow:</strong> If the debt-to-equity ratio is high and cash flow from operations is negative, the company might struggle to pay its debts.</li>
          <li><strong>Low Current Ratio:</strong> A current ratio below 1 suggests the company might not be able to cover short-term liabilities.</li>
          <li><strong>Inconsistent Cash Flow:</strong> If cash flow from operations is negative while net income is positive, the company might be using accounting tricks to inflate profits.</li>
          <li><strong>Frequent Losses:</strong> Occasional losses are normal, but consistent losses over multiple years are a bad sign—unless the company is a startup investing heavily in growth.</li>
        </ul>
      </section>

      <section>
        <h2>How to Analyze Trends</h2>
        <p>
          Numbers alone do not tell the whole story—you need to look at trends over time. Pull financial statements from the past 3–5 years and compare:
        </p>
        <ul>
          <li>Is revenue growing, stagnant, or declining?</li>
          <li>Are profit margins improving or shrinking?</li>
          <li>Is debt increasing faster than equity or revenue?</li>
          <li>Is cash flow from operations consistent and positive?</li>
        </ul>
        <p>
          Trends reveal whether the company is on an upward trajectory or heading for trouble. A company with growing revenue, stable margins, and positive cash flow is generally a safer bet than a cash flow with erratic numbers.
        </p>
      </section>

      <section>
        <h2>Understanding the Industry Context</h2>
        <p>
          Not all companies are the same. A tech startup might have high debt and negative cash flow because it is investing in growth, while a utility company should have stable cash flow and low debt. Always compare a company’s financials to its industry peers:
        </p>
        <ul>
          <li>Look up industry averages for metrics like profit margins, debt-to-equity, and ROE (you can find these on financial sites like Yahoo Finance or through industry reports).</li>
          <li>If a company’s metrics are significantly worse than its peers, dig deeper to understand why.</li>
        </ul>
      </section>

      <section>
        <h2>Practical Steps to Dive In</h2>
        <p>
          Here is how to start analyzing a company’s financials yourself:
        </p>
        <ul>
          <li><strong>Pick a Company:</strong> Choose a public company you are interested in (e.g., Apple, Tesla, or a company you want to invest in).</li>
          <li><strong>Get the Financial Statements:</strong> Download their latest annual report (10-K) from their website or the SEC’s EDGAR database.</li>
          <li><strong>Start with the Income Statement:</strong></li>
          <li>Check revenue and net income trends over the past 3 years.</li>
          <li>Calculate the net margin and compare it to competitors.</li>
          <li><strong>Move to the Balance Sheet:</strong></li>
          <li>Look at assets, liabilities, and equity.</li>
          <li>Calculate the debt-to-equity and current ratios.</li>
          <li><strong>Review the Cash Flow Statement:</strong></li>
          <li>Focus on cash flow from operations—is it positive and growing?</li>
          <li>Check if the company is spending heavily on investing or financing activities.</li>
          <li><strong>Look for Red Flags:</strong> Identify any warning signs like declining revenue, high debt, or negative cash flow.</li>
          <li><strong>Compare to Peers:</strong> Use industry benchmarks to see how the company stacks up.</li>
          <li><strong>Read the Notes:</strong> Financial statements include notes that explain the numbers in detail—do not skip them. They often reveal important context, like upcoming risks or accounting methods.</li>
        </ul>
      </section>

      <section>
        <h2>Summary</h2>
        <p>
          Understanding company financials is not just for investors—it is a skill that helps you make better decisions in all areas of life. If you are investing, it helps you pick companies that are likely to grow your money. If you are job hunting, it shows you which companies are stable employers. Even as a consumer, it lets you see if the companies you rely on are here to stay.
        </p>
        <p>
          The more you practice, the better you will get at spotting opportunities and avoiding risks. Take control of your financial literacy by diving into these statements yourself.
        </p>
      </section>

      {/* ✅ Disclaimer Section Styled Like the Example */}
      <section className="disclaimer-section">
        <div className="disclaimer-container">
          <h2 className="disclaimer-title">SEC EDGAR Database</h2>
          <div className="disclaimer-button-container">
            <a
              href="https://sec.gov/edgar"
              target="_blank"
              rel="noopener noreferrer"
              className="disclaimer-button"
            >
              Visit EDGAR
            </a>
          </div>
          <div className="disclaimer-text-container">
            <p className="disclaimer-text">
              <em>
                Source: <a href="https://sec.gov/edgar" target="_blank" rel="noopener noreferrer">SEC EDGAR</a>. 
                This tool is provided by a third party. We are not affiliated with or responsible for its accuracy.
              </em>
            </p>
          </div>
        </div>
      </section>

      {/* ✅ Add Application Home Button */}
      <div className="cta-container">
        <Link to="/practical-applications" className="cta-button">Application Home</Link>
      </div>

    </div>
  );
};

export default Statements;